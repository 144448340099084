var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pl-0 pr-0"},[_c('PrettyAlert',_vm._b({model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},'PrettyAlert',_vm.alertOptions,false)),(_vm.confirmDialog)?_c('ConfirmDialog',{attrs:{"title":_vm.confirmDialogTitle,"confirmationText":_vm.confirmDialogText,"eventName":_vm.confirmDialogEvent,"actions":_vm.customActions},on:{"close":function($event){_vm.confirmDialog = false}},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"360px"},model:{value:(_vm.managementDialog),callback:function ($$v) {_vm.managementDialog=$$v},expression:"managementDialog"}},[_c('v-card',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Gerencias")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center mt-4 mb-5"},[_c('p',[_vm._v(_vm._s(_vm.management_dialog_text))])])],1)],1)],1)],1),_c('CrudTable',{attrs:{"storeModule":_vm.storeModule,"headers":_vm.headers,"filterItems":_vm.filterItems,"customOptions":_vm.customOptions,"customActions":_vm.customActions,"parentKey":this.parentKey,"parentValue":this.parentValue,"editableValues":_vm.editableValues,"isFormLoading":_vm.isFormLoading},scopedSlots:_vm._u([{key:"item.status.internal_status",fn:function(ref){
var item = ref.item;
return [_c('CustomCell',{attrs:{"value":item.status.internal_status.status_key,"chip":true}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'AgreementDetail', params: { id: item.external_id }}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.distributor.managements",fn:function(ref){
var item = ref.item;
return [(typeof item.distributor.managements === 'string')?_c('p',[_vm._v(" "+_vm._s(item.distributor.managements)+" ")]):(item.distributor.managements.length > 2)?_c('v-btn',{staticClass:"pl-0 pr-0",staticStyle:{"font-weight":"normal"},attrs:{"text":""},on:{"click":function($event){return _vm.onManagementDialogOpen(item.distributor)}}},[_vm._v(" "+_vm._s(_vm.parseManagementList(item.distributor.managements))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.parseManagementList(item.distributor.managements))+" ")])]}},{key:"form",fn:function(ref){
var close = ref.close;
return [_c('AgreementForm',{attrs:{"loading":_vm.isFormLoading},on:{"save":function($event){return _vm.afterCreation()},"close":close,"update:loading":function($event){_vm.isFormLoading=$event}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }