<template>
  <v-container class="pl-0 pr-0">
    <PrettyAlert v-bind="alertOptions" v-model="showAlert"/>
    <ConfirmDialog
      v-if="confirmDialog"
      v-model="confirmDialog"
      :title="confirmDialogTitle"
      :confirmationText="confirmDialogText"
      :eventName="confirmDialogEvent"
      :actions="customActions"
      v-on:close="confirmDialog = false"
    ></ConfirmDialog>

    <v-dialog v-model="managementDialog" max-width="360px">
      <v-card>
        <v-container>
          <v-row no-gutters>
            <v-col>
              <v-card-title>
                <span class="headline">Gerencias</span>
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center mt-4 mb-5">
              <p>{{management_dialog_text}}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <CrudTable :storeModule="storeModule" :headers="headers" :filterItems="filterItems"
             :customOptions="customOptions" :customActions="customActions"
             :parentKey="this.parentKey" :parentValue="this.parentValue"
             :editableValues="editableValues" :isFormLoading="isFormLoading">

      <template v-slot:item.status.internal_status="{item}">
        <CustomCell :value="item.status.internal_status.status_key" :chip="true"/>
      </template>

      <template v-slot:item.name="{ item }">
        <router-link :to="{name: 'AgreementDetail', params: { id: item.external_id }}">
          {{ item.name }}
        </router-link>
      </template>

      <template v-slot:item.distributor.managements="{ item }">
        <p v-if="typeof item.distributor.managements === 'string'">
          {{item.distributor.managements}}
        </p>
        <v-btn
            @click="onManagementDialogOpen(item.distributor)"
            class="pl-0 pr-0"
            style="font-weight: normal"
            text v-else-if="item.distributor.managements.length > 2">
          {{parseManagementList(item.distributor.managements)}}
        </v-btn>
        <p v-else>
          {{parseManagementList(item.distributor.managements)}}
        </p>
      </template>

      <template v-slot:form="{ close }">
        <AgreementForm @save="afterCreation()" @close="close" :loading.sync="isFormLoading"/>
      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import ConfirmDialog from '@/apps/core/components/ConfirmDialog'
import { mapActions, mapState } from 'vuex'
import AgreementForm from '../forms/AgreementForm'
import Lget from 'lodash/get'
import CustomCell from '@/lib/uncrudtable/components/cells/CustomCell'
import { labelsMixin } from '@/apps/core/mixins/cosmos_labels'
import { debounce, isString } from 'lodash'

export default {
  name: 'AgreementTable',
  components: { AgreementForm, CrudTable, CustomCell, PrettyAlert, ConfirmDialog },
  mixins: [labelsMixin],

  props: ['parentKey', 'parentValue', 'editableValues'],

  data () {
    return {
      storeModule: 'agreements',
      showAlert: false,
      alertOptions: { text: '', color: '' },
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      confirmDialogEvent: '',
      onConfirmActionParams: null,
      managementDialog: false,
      loadingFinalStatusAgreement: false,
      inputSearch: '',
      renovated_type_agreements: [],
      management_dialog_text: '',
      management_list: [],
      final_state_agreement: null,
      isFormLoading: false,
      customOptions: {
        'sortBy': ['id'],
        'sortDesc': [true]
      },
      headers: [
        { text: 'id', value: 'id', hide: false },
        { text: 'Nombre', value: 'name', hide: false, width: '250px' },
        { text: 'Fecha inicio real', value: 'real_start_date', hide: false },
        { text: 'Fecha fin real', value: 'real_end_date', hide: false },
        { text: 'Cliente', value: 'distributor.name', hide: false },
        { text: 'Creador', value: 'created_by.user.username', hide: false },
        { text: 'Posición', value: 'created_by.position', hide: false },
        { text: 'Gerencia (s)', value: 'distributor.managements', hide: false },
        { text: 'Estado', value: 'status.name', hide: false, chip: true },
        { text: 'Total (€)', value: 'amount', formatNumber: true, align: 'end', hide: false, total: 'total_sell' },
        { text: 'Tipo', value: 'agreement_type.name', align: 'end', hide: false },
        { text: 'Renovado desde', value: 'renovated_from.name', align: 'end', hide: false }
      ],
      customActions: [
        {
          name: 'download-complete-agreement',
          text: 'Exportar Acuerdo/s',
          icon: 'fa-download',
          action: this.onDownloadAgreement
        },
        {
          name: 'modify-comercial-conditions-acp',
          text: 'Modificar y Cerrar ACP',
          icon: 'fa-copy',
          action: this.confirmModifyCommercialCondition,
          onConfirmAction: this.modifyCommercialCondition
        },
        {
          name: 'close-and-duplicate',
          text: 'Modificar y Cerrar Acuerdo',
          icon: 'fa-copy',
          action: this.confirmCloseAndDuplicateSelected,
          onConfirmAction: this.closeAndDuplicateSelected
        },
        {
          name: 'close',
          text: 'Cerrar Acuerdo',
          icon: 'fa-copy',
          action: this.confirmCloseSelected,
          onConfirmAction: this.closeSelected
        },
        {
          name: 'duplicate',
          text: 'Duplicar Acuerdo',
          icon: 'fa-copy',
          action: this.confirmDuplicateSelectedAgreements,
          onConfirmAction: this.duplicateSelectedAgreements
        },
        {
          name: 'delete',
          text: 'Borrar (rechazados y borradores)',
          icon: 'fa-trash',
          action: this.confirmDeleteSelectedAgreements,
          onConfirmAction: this.deleteSelectedAgreements
        },
        {
          name: 'approve',
          text: 'Pasar de Pdte Revisión a Aprobado',
          icon: 'fa-trash',
          action: this.confirmApproveSelectedAgreements,
          onConfirmAction: this.approveSelectedAgreements
        },
        {
          name: 'expire',
          text: 'Pasar de Pdte Revisión a Rechazado',
          icon: 'fa-trash',
          action: this.confirmExpireSelectedAgreements,
          onConfirmAction: this.expireSelectedAgreements
        }
      ]
    }
  },

  watch: {
    inputSearch (val) {
      if (isString(val) && val.length > 0) {
        this.queryAgreements(val)
      }
    }
  },

  computed: {
    ...mapState('agreements', [
      'extra_fields',
      'finalStatusList',
      'filterItems',
      'currentItem',
      'messageData'
    ])
  },

  created () {
    this.setResetCurrentItem()
  },
  methods: {
    ...mapActions('agreements', [
      'reloadItemList',
      'cloneAgreements',
      'closeAgreements',
      'closeAndCloneAgreements',
      'getFinalStatusItemList',
      'postManyItems',
      'modifyAcpCondition',
      'getItemList',
      'deleteItems',
      'setResetCurrentItem',
      'exportAsExcel'
    ]),
    Lget: Lget,

    queryAgreements: debounce(function (input) {
      this.loadingFinalStatusAgreement = true
      this.getFinalStatusItemList({
        search: input
      })
      this.loadingFinalStatusAgreement = false
    }, 500),
    afterCreation () {
      this.reloadItemList()
    },
    showAlertAction (text, color) {
      this.alertOptions = { text, color }
      this.showAlert = true
    },

    openConfirmDialog ({ title, text, event }, onConfirmActionParams = null) {
      this.confirmDialogTitle = title
      this.confirmDialogText = text
      this.confirmDialogEvent = event
      this.onConfirmActionParams = onConfirmActionParams
      this.confirmDialog = true
    },

    confirmModifyCommercialCondition (agreements) {
      let invalidAgreements = ''
      let validAgreements = []
      for (const agreement of agreements.items) {
        if (agreement.agreement_type.name === 'ACP' && agreement.status.internal_status.status_value === 1) {
          validAgreements.push(agreement)
        } else {
          invalidAgreements += `, ${agreement.name}`
        }
      }
      if (invalidAgreements.length > 0) {
        let msg = (invalidAgreements.indexOf(',') !== -1)
          ? `Los acuerdos "${invalidAgreements}" no son de tipo ACP o no estan en vigor.`
          : `El acuerdo "${invalidAgreements}" no es de tipo ACP o no esta en vigor.`
        this.showAlertAction(msg, 'warning')
      }
      if (validAgreements.length > 0) {
        this.openConfirmDialog({
          title: 'Modificar condición ACP',
          text: '¿Seguro que quieres duplicar y cerrar el ACP seleccionado?',
          event: 'modify-comercial-conditions-acp'
        }, validAgreements)
      }
    },

    async modifyCommercialCondition () {
      try {
        const response = await this.modifyAcpCondition(this.onConfirmActionParams)
        // await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
        this.confirmDialog = false
        await this.$router.push({
          name: 'AgreementDetail',
          params: { id: response.data[0].agreement }
        })
      } catch (error) {
        this.confirmDialog = false
        this.showAlertAction('Ha ocurrido un error en el proceso de modificación del ACP', 'error')
      }
    },

    confirmCloseAndDuplicateSelected (data) {
      const itemsToPost = data.items.map(item => ({
        external_id: item.external_id,
        agreement_type: item.agreement_type == null ? '-' : item.agreement_type.name,
        status: item.status
      }))
      if (Object.keys(itemsToPost).length > 1) {
        this.showAlertAction('Debe de seleccionar sólo un acuerdo comercial', 'warning')
      } else if (itemsToPost.find(it => {
        return it.agreement_type === 'ACP' && it.status.internal_status !== 3
      })) {
        this.showAlertAction('No se pueden cerrar los acuerdos de tipo ACP', 'warning')
      } else {
        this.openConfirmDialog({
          title: 'Modificar y cerrar acuerdo',
          text: '¿Seguro que quieres duplicar y cerrar el elemento seleccionado?',
          event: 'close-and-duplicate'
        }, itemsToPost)
      }
    },

    async closeAndDuplicateSelected () {
      try {
        await this.closeAndCloneAgreements(this.onConfirmActionParams)
        this.showAlertAction('Se ha realizado la modificación y el cerrado correctamente', 'success')
        this.reloadItemList()
      } catch (error) {
        this.showAlertAction('Ha ocurrido un error realizando la operación sobre el acuerdo', 'error')
      } finally {
        this.confirmDialog = false
      }
    },

    confirmCloseSelected (data) {
      const itemsToPost = data.items.map(item => ({
        external_id: item.external_id,
        agreement_type: item.agreement_type == null ? '-' : item.agreement_type.name,
        status: item.status
      }))
      if (Object.keys(itemsToPost).length > 1) {
        this.showAlertAction('Debe de seleccionar sólo un acuerdo comercial', 'warning')
      } else if (itemsToPost.find(it => {
        return it.agreement_type === 'ACP' &&
          it.status.internal_status !== 3
      })) {
        this.showAlertAction('No se pueden cerrar los acuerdos de tipo ACP', 'warning')
      } else {
        this.openConfirmDialog({
          title: 'Cerrar acuerdo',
          text: '¿Seguro que quieres cerrar el elemento seleccionado?',
          event: 'close'
        }, itemsToPost)
      }
    },

    async closeSelected () {
      try {
        await this.closeAgreements(this.onConfirmActionParams)
        this.showAlertAction('Se ha realizado el cerrado correctamente', 'success')
        this.reloadItemList()
      } catch (error) {
        this.showAlertAction('Ha ocurrido un error en la operación de cerrado', 'error')
      } finally {
        this.confirmDialog = false
      }
    },

    confirmDuplicateSelectedAgreements (data) {
      const itemsToPost = data.items.map(item => ({
        external_id: item.external_id,
        agreement_type: item.agreement_type == null ? '-' : item.agreement_type.name,
        status: item.status
      }))
      if (Object.keys(itemsToPost).length > 1) {
        this.showAlertAction('Debe de seleccionar sólo un acuerdo comercial', 'warning')
      } else if (itemsToPost.find(it => {
        return it.agreement_type === 'ACP' &&
          it.status.internal_status.status_value !== 6 &&
          it.status.internal_status.status_value !== 3
      })) {
        this.showAlertAction(
          'No se pueden duplicar los acuerdos de tipo ACP que no estén vencidos o rechazados.',
          'warning'
        )
      } else {
        this.openConfirmDialog({
          title: 'Duplicar acuerdo',
          text: '¿Seguro que quieres duplicar el elemento seleccionado?',
          event: 'duplicate'
        }, itemsToPost)
      }
    },

    async duplicateSelectedAgreements () {
      try {
        await this.cloneAgreements(this.onConfirmActionParams)
        this.showAlertAction('Se ha realizado el duplicado correctamente', 'success')
        this.reloadItemList()
      } catch (error) {
        this.showAlertAction('Ha ocurrido un error en la operación de duplicado', 'error')
      } finally {
        this.confirmDialog = false
      }
    },

    confirmDeleteSelectedAgreements (data) {
      let invalidStatus = 0
      data.items.filter(r => {
        if (r.status.internal_status.status_key !== 'Draft' && r.status.internal_status.status_key !== 'Rejected') {
          invalidStatus++
        }
      })
      if (invalidStatus === 0) {
        this.openConfirmDialog({
          title: 'Borrar acuerdo',
          text: '¿Seguro que quieres borrar el elemento seleccionado?',
          event: 'delete'
        }, data.items)
      } else {
        this.showAlertAction('Sólo se pueden borrar los acuerdos comerciales rechazados o borradores', 'warning')
      }
    },

    confirmApproveSelectedAgreements (data) {
      const itemsToPost = data.items.map(item => ({
        external_id: item.external_id
      }))
      this.openConfirmDialog({
        title: 'Aprobar acuerdos seleccionados',
        text: '¿Seguro que quieres aprobar los elementos seleccionados?',
        event: 'approve'
      }, itemsToPost)
    },

    confirmExpireSelectedAgreements (data) {
      const itemsToPost = data.items.map(item => ({
        external_id: item.external_id
      }))
      this.openConfirmDialog({
        title: 'Rechazar acuerdos seleccionados',
        text: '¿Seguro que quieres rechazar los elementos seleccionados?',
        event: 'expire'
      }, itemsToPost)
    },

    async deleteSelectedAgreements () {
      try {
        await this.deleteItems(this.onConfirmActionParams)
        this.showAlertAction('Se ha realizado el borrado correctamente', 'success')
        this.reloadItemList()
      } catch (error) {
        this.showAlertAction('Ha ocurrido un error en la operación de borrado', 'error')
      } finally {
        this.confirmDialog = false
      }
    },

    async approveSelectedAgreements () {
      try {
        await this.$store.dispatch(`agreements/approveListFromPendingReview`, this.onConfirmActionParams).then(() => {
          this.showAlertAction(this.messageData.data.message, this.messageData.data.status)
          this.reloadItemList()
        })
      } catch (error) {
        this.showAlertAction(this.messageData.data.message, this.messageData.data.status)
      } finally {
        this.confirmDialog = false
      }
    },

    async expireSelectedAgreements () {
      try {
        await this.$store.dispatch(`agreements/expireListFromPendingReview`, this.onConfirmActionParams).then(() => {
          this.showAlertAction(this.messageData.data.message, this.messageData.data.status)
          this.reloadItemList()
        })
      } catch (error) {
        this.showAlertAction(this.messageData.data.message, this.messageData.data.status)
      } finally {
        this.confirmDialog = false
      }
    },

    parseManagementList (list) {
      let txt = ''
      let realLen = list.length
      let len = list.length
      if (len > 2) {
        len = 2
      }
      for (let i = 0; i < len; i++) {
        if (list[i] !== undefined && list[i] !== null) {
          txt += list[i]
          if ((i + 1) === len - 1 && realLen < 3) {
            txt += ' y '
          } else if (i !== len - 1) {
            txt += ', '
          }
        }
      }
      if (list.length > 2) {
        txt += `, ...`
      }
      return txt
    },

    duplicateSelected (data) {
      // reset external_id because if not update item and dont duplicate it
      let itemsToPost = data.items
        .map(item => ({
          ...item,
          distributor: item.distributor.external_id,
          status: item.status.internal_status.status_value,
          rate: item.rate.external_id,
          id: null,
          external_id: null
        }))
      confirm('¿Seguro que quieres duplicar los elementos seleccionados?') &&
        this.$store
          .dispatch(`${this.storeModule}/postManyItems`, itemsToPost).catch((error) => {
            console.log(error)
          }).then(
            () => this.reloadItemList()
          )
    },

    async onManagementDialogOpen (distributor) {
      this.managementDialog = true
      this.management_dialog_text = ''
      const managements = distributor.managements
      for (let i = 0; i < managements.length; i++) {
        if (managements[i] !== undefined && managements[i] !== null) {
          this.management_dialog_text += managements[i]
          if ((i + 1) === managements.length - 1) {
            this.management_dialog_text += ' y '
          } else if (i !== managements.length - 1) {
            this.management_dialog_text += ', '
          }
        }
      }
    },

    async onDownloadAgreement (agreements) {
      agreements = agreements['items'].map(agreement => agreement.id)
      if (agreements.length > 0) {
        await this.exportAsExcel(agreements)
      }
    }
  }
}
</script>

<style scoped>

</style>
