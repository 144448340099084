<template>
  <v-dialog
    :value.sync="value"
    :width="width"
    :persistent="isLoading"
    @click:outside="onHandleClickOutside"
  >
    <v-card>
      <v-card-title class="grey lighten-3 py-5">
        <slot name="header">
          <span class="headline font-weight-medium">{{ title }}</span>
        </slot>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-container>
          <template v-if="isLoading">
            <v-row no-gutters>
              <v-col>
                <v-card-text class="text-center">Espere un momento, por favor.</v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center mt-4 mb-5">
                <v-progress-circular color="primary" indeterminate :size="70" :width="7" />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row no-gutters>
              <v-col>
                <v-card-text class="title">{{ confirmationText }}</v-card-text>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions class="grey lighten-4 py-3">
        <slot name="actions">
          <v-spacer/>
          <v-btn color="primary" v-on:click="onAccept" :disabled="isLoading">
            Aceptar
          </v-btn>
        </slot>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
/**
 * ConfirmDialog component:
 *   To be used to request confirmation upon performing an action/request, and can be used with a CrudTable's customActions.
 *   The 'title' and 'confirmationText' props define the dialog's text
 *
 * Example implementation: AgreementTable.vue
 *
 * 1. When used with a CrudTable's customActions:
 *   - customActions are passed into the 'actions' prop
 *   - Each CrudTable's "customAction.action" to require confirmation should:
 *     *) Open the ConfirmDialog with the value prop (in the example implementation: 'openConfirmDialog')
 *     *) Define the "eventName" prop, which has to match the "customAction.name" field.
 *     *) Have a "customAction.onConfirmAction" which is to be called upon confirmation
 *   - The dialog must be closed manually at the "onConfirmAction" method
 *
 * 2. When used for individual confirmations:
 *   - The "actions" prop is not possed and the "accept" event is to be listened
 *   - To use the same ConfirmDialog instance with more than one confirmation, the "eventName" prop can be used for each case and
 *     be subsequently mapped in the parent
 */

export default {
  name: 'ConfirmDialog',

  props: {
    value: { type: Boolean, required: true },
    width: { type: String, default: '800px' },
    title: { type: String, required: true },
    confirmationText: { type: String, default: null },
    eventName: { type: String, default: null },
    actions: { type: Array, default: () => null }
    // eventParams: { type: [ Object ], default: () => {} }
  },

  data () {
    return {
      isLoading: false
    }
  },

  created () {
    this.confirmationText.default = this.$t('customActions.operationQuestion')
  },

  methods: {
    onAccept () {
      this.isLoading = true
      if (this.actions) {
        let eventAction = this.actions.find(action => action.name === this.eventName)
        if (eventAction) {
          eventAction.onConfirmAction()
        }
      } else {
        this.$emit('accept', this.eventName)
      }
    },
    onHandleClickOutside () {
      if (!this.isLoading) {
        this.$emit('close')
      }
    }
  }
}
</script>
