<template>
  <Layout :topBarTitle="title" :sections="sections">
    <AgreementTable/>
  </Layout>
</template>

<script>
import AgreementTable from '../components/tables/AgreementTable'
import Layout from '@/apps/core/components/Layout'
import sections from './navigationDrawerSections'

export default {
  components: { Layout, AgreementTable },
  data () {
    return {
      title: 'Acuerdos comerciales',
      sections: sections
    }
  }
}
</script>
