<template>
  <div>
    <v-card>
      <v-form
        ref="form"
        @submit.prevent="submit"
      >
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="pa-5"
              >
                <v-text-field
                  v-model="name"
                  label="Nombre *"
                  placeholder="Nombre del Acuerdo"
                  :rules="[ (x) => x? true : 'Este campo es requerido']"
                  :disabled="loading"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="rate"
                  class="pa-2"
                  :items="validRates"
                  item-value="external_id"
                  item-text="name"
                  label="Tarifa *"
                  required
                  prepend-icon="fa-coins"
                  :rules="[ (x) => x? true : 'Este campo es requerido']"
                  :disabled="loading"
                />
              </v-col>
            </v-row>
            <SelectOrCreateDistributor @close="dialog=false" :loadingAgreement="loading" />
          </v-container>
        </v-card-text>

        <v-card-actions class="mr-5">
          <v-spacer/>
          <v-btn
            color="primary"
            @click="onSave"
            :loading="loading"
            :disabled="onSaveDisabled"
          >
            Crear Acuerdo
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <PrettyAlert :text="textAlert" v-model="showAlert"/>
  </div>
</template>

<script>
import get from 'lodash/get'
import { mapState, mapActions } from 'vuex'
import SelectOrCreateDistributor from './../SelectOrCreateDistributor'
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import { isNull, isUndefined } from 'lodash'

export default {
  name: 'AgreementForm',
  components: { PrettyAlert, SelectOrCreateDistributor },
  props: {
    dialog: Boolean,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formTitle: 'Nuevo Acuerdo',
      name: null,
      rate: null,
      agreementType: null,
      showAlert: false,
      textAlert: '',
      onSaveDisabled: true,
      agreementFormValues: {
        name: null,
        rate: null,
        distributor: null
      },
      rules: {
        empty: [val => this.isEmpty(val)]
      }
    }
  },
  computed: {
    ...mapState('agreements', ['currentItem', 'validRates']),
    // newItem computed object to send only the necessary data
    newItem: function () {
      const distExternalId = get(this, 'agreementFormValues.distributor.external_id', null)
      const username = localStorage.getItem('user')
      return {
        // Here we have all the data that will post to the backend
        name: this.agreementFormValues.name,
        distributor: String(distExternalId) === '-1' ? get(this, 'agreementFormValues.distributor.newName', null) : distExternalId,
        rate: this.agreementFormValues.rate,
        status: { internal_status: '0', status_type: '2' }, // The back needs that we pass the draft status for the new agreements, so we suppose that the status with internal_id == 0 exists in the backend
        created_by: username,
        last_modified_by: username,
        created_from: 1
      }
    },
    newDistributorGroup: function () {
      const groupLevel = 1 // TODO maybe move this const to some file
      const newGroupId = this.currentItem.newDistributorGroup.map(x => get(x, 'external_id', x)).join('+')
      return [{
        external_id: newGroupId,
        name: `group ${newGroupId}`,
        level: groupLevel
      }].concat(this.currentItem.newDistributorGroup.map(x => ({
        name: x,
        parent: newGroupId,
        level: groupLevel + 1
      })))
    }
  },
  watch: {
    dialog: 'initialize',
    name: function (nVal, oVal) {
      if ((!isNull(nVal) || !isUndefined(nVal) || nVal !== '') && nVal !== oVal) {
        this.agreementFormValues.name = nVal
        this.onSaveActivation()
      }
    },
    rate: function (nVal, oVal) {
      if ((!isNull(nVal) || !isUndefined(nVal) || nVal !== '') && nVal !== oVal) {
        this.agreementFormValues.rate = nVal
        this.onSaveActivation()
      }
    },
    currentItem: function (nVal, oVal) {
      if ((!isNull(nVal.distributor.external_id) || !isUndefined(nVal.distributor.external_id) || nVal.distributor.external_id !== '') && nVal.distributor.external_id !== oVal.distributor.external_id) {
        this.agreementFormValues.distributor = nVal.distributor
        this.onSaveActivation()
      }
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    ...mapActions('agreements', ['clearCurrentItem', 'getValidRates', 'setPaymentTypeCondition', 'postItem']),
    initialize () {
      this.$emit('clearCurrentItem')
      this.getValidRates()
    },

    closeForm () {
      this.$emit('clearCurrentItem')
      this.$emit('close')
    },

    showAlertAction (text) {
      this.textAlert = text
      this.showAlert = true
    },
    /**
     * Function used to created a agreement
     * */
    async onSave () {
      if (this.$refs.form.validate()) {
        try {
          this.$emit('update:loading', true)
          const response = await this.postItem(this.newItem)
          const agreementExternalId = response.data.identification_ids[0]
          await this.setPaymentTypeCondition(agreementExternalId)
          this.$emit('update:loading', false)
          await this.$router.push({
            name: 'AgreementDetail',
            params: { id: agreementExternalId }
          })
        } catch (e) {
          this.showAlertAction('Error creando el acuerdo')
          this.$emit('update:loading', false)
        }
      }
      this.agreementFormValues = {}
    },
    onSaveActivation () {
      let validationName = !isNull(this.agreementFormValues.name) && this.agreementFormValues.name !== ''
      let validationRate = !isNull(this.agreementFormValues.rate)
      let validationDis = !isNull(get(this, 'agreementFormValues.distributor.external_id', null))
      if (validationName && validationRate && validationDis) {
        this.onSaveDisabled = false
      } else {
        this.onSaveDisabled = true
      }
    }

  }
}
</script>

<style scoped>

</style>
